.card__container {
  background-color: #FFA14A;
  border-radius: 6px;
  height: 270px;
  overflow: hidden;
  position: relative;
  width: 320px;
}

.card__container img {
  border-radius: 6px;
  height: 268px;
  left: 1px;
  margin: auto;
  position: absolute;
  top: 1px;
  width: 318px;
}

.tag {
  align-items: center;
  background: linear-gradient(264.31deg,
      rgba(73, 73, 73, 0.9) 55.91%,
      rgba(99, 103, 103, 0.5) 81.84%,
      rgba(184, 186, 186, 0.1) 98.91%);
  border-radius: 0px 0px 6px 0px;
  display: flex;
  flex-wrap: wrap;
  height: 54px;
  justify-content: flex-end;
  position: absolute;
  right: -1;
  top: 215px;
  transition: all .6s ease-in-out ;
  width: 318px;
}

.tag span {
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin-right: 5px;
}

.card__container:hover .list__container {
  top: 0
}

.list__container {
  align-items: center;
  background-color: #ce6809cd;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  height: 100%;
  position: relative;
  text-transform: uppercase;
  top: -120%;
  transition:.7s linear;
  width: 100%;
  z-index: 20;
}

.card__container:hover .tag {
  top: 100%;
}

.list__container ul {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
}

@media (max-width: 600px) {
  .card__container {
    height: 119px;
  }
  
  .card__container:hover .tag {
    top: 70px;
  }

  .card__container:hover .list__container {
    top: -999px
  }

  .tag {
    left: 1px;
    top: 70px;
  }

  .list__container ul {
    display: none;
  }

  .card__container img {
    height: 120%;
    margin: auto;
    position: absolute;
    width: 318px;
  }
}
