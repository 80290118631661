.carousel__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item__container {
  align-items: center;
  display: flex;
  height: 201px;
  position: relative;
  transition: all .5s ease-in-out;
  width: 246px;
}

.carousel__container button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 40px;
  font-weight: 800;
  height: 40px;
  padding: 0;
}

.carousel__item {
  position: absolute;
  left: 400px;
}