.info {
  align-items: center;
  background-color: #0A0E1B;
  display: flex;
  height: 262px;
  justify-content: space-between;
  padding: 34px;
}

.info img {
  height: 129px;
  width: 318px;
}

.info__container {
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  justify-content: space-between;
  width: 800px;
}

.info__container h2 {
  font-size: 32px;
}

.address a, .email{
  color: white;
  text-decoration: none;
}

.address a:hover, .email:hover, .wpp:hover{
  color: #FFA14A;
}

.wpp {
  color: #05FF00;
  font-weight: 600;
  text-decoration: none;
}

.copyright {
  align-items: center;
  background-color: #070A13;
  color: white;
  display: flex;
  font-size: 20px;
  height: 55px;
  justify-content: center;
}

.copyright a {
  color: white;
  font-weight: 800;
  text-decoration: none;
}

.copyright a:hover {
  color: #05FF00;
}

@media (max-width: 600px) {
  .info {
    height: 459px;
    position: relative;
  }
  
  .info__container {
    align-items: center;
    flex-direction: column;
    gap: 67px;
    z-index: 22;
  }

  .info img {
    height: 330px;
    position: absolute;
    right: calc(50% - 200px);
    opacity: 20%;
    width: 400px;
  }

  .copyright {
    height: 140px;
    text-align: center;
  }
}