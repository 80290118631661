/* .header__container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.404);
  display: flex;
  height: 100px;
  justify-content: space-between;
  width: 100%;
} */

.header__container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.404);
  display: flex;
  height: 100px;
  justify-content: flex-end;
  right: 0;
  top: 0;
  width: 100%;
}

.nav__container {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-left: 34px;
  margin-top: 4px;
}

.nav__container a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  transition: all ease-in-out 250ms;
}

.current__page {
  border-bottom: #FFA14A solid 4px;
  font-weight: bold;
  margin-top: 6px;
  padding-bottom: 2px;
}

.current__page span {
  color: #FFA14A;
}

.header__container img {
  height: 88px;
  margin-right: 34px;
  width: 145px;
}

.hamburguer__container {
  align-items: center;
  display: none;
  flex-direction: column;
  height: 24px;
  justify-content: center;
  margin-left: 16px;
  position: relative;
  transition: all .5s ease-in-out;
  width: 30px;
}

.hamburguer__stick {
  background-color: #fff;
  height: 4px;
  width: 100%;
  transition: all .5s ease-in-out;
}

.hamburguer__stick::before,
.hamburguer__stick::after {
  background-color: #ffffff;
  content: '';
  height: 4px;
  position: absolute;
  transition: all .5s ease-in-out;
  width: 100%;
}

.hamburguer__stick::before {
  transform: translateY(-9px);
}

.hamburguer__stick::after {
  transform: translateY(9px);
}

.hidden__nav {
  position: absolute;
  top: -101vh;
}

@media (max-width: 600px) {
  .header__container img {
    height: 61px;
    margin-right: 16px;
    width: 98px;
  }

  .hidden__nav {
    align-items: center;
    background-color: rgba(3, 3, 3, 0.631);
    display: flex;
    flex-direction: column;
    gap: 70px;
    height: 100vh;
    justify-content: center;
    position: absolute;
    top: -101vh;
    transition: all .6s ease-in-out;
    width: 100vw;
  }

  .nav__container {
    display: none;
  }

  .nav__mobile {
    align-items: center;
    background-color: black;
    display: flex;
    flex-direction: column;
    gap: 70px;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    transition: all .6s ease-in-out;
    width: 100vw;
    z-index: -1;
  }

  .hidden__nav a,
  .nav__mobile a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    transition: all ease-in-out 250ms;
  }


  .hamburguer__container {
    display: flex;
  }

  .show__x .hamburguer__stick {
    transform: translateX(-50px);
    background: transparent;
  }

  .show__x .hamburguer__stick::before {
    border-radius: 6px;
    transform: rotate(45deg) translate(35px, -35px);
  }

  .show__x .hamburguer__stick::after {
    border-radius: 6px;
    transform: rotate(-45deg) translate(35px, 35px);
  }
}