* {
  scroll-behavior: smooth;
}

.home__page {
  background-color: #F6F9FF;
  position: relative;
  overflow: hidden;
}

.home__page header {
  position: fixed;
  z-index: 20;
}

.drone__image {
  background-attachment: fixed;
  background-image: url(../../images/drone_compact.jpg);
  background-position: center;
  background-size: cover;
  height: 900px;
  top: 0;
  width: 100%;
}

.drone__image img {
  position: absolute;
  width: 330px;
  height: 233, 64px;
  right: 34px;
  top: 660px;
}

.presentation__text {
  position: absolute;
  left: 34px;
  top: 40px;
  width: 550px;
}

.presentation__text span {
  color: white;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 100px;
  font-weight: 600;
  text-wrap: wrap;
}

.services {
  height: fit-content;
  min-height: 650px;
  padding: 115px 0;
  width: 100%;
}

.section__title {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
}

.section__title h1 {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.section__title hr {
  background-color: #FFA14A;
  border: none;
  height: 4px;
  margin: 0;
  width: 76px;
}

.cards__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  justify-content: center;
  margin: 115px auto 0 auto;
  min-height: fit-content;
  width: 70%;
}

.transition__section {
  background-attachment: fixed;
  background-image: url(../../images/trator-no-campo.jpg);
  background-size: cover;
  background-position: center;
  height: 680px;
  width: 100%;
}

.trademark__container {
  align-items: center;
  background: conic-gradient(from 160.44deg at 63.59% 0%,
      rgba(255, 254, 254, 0) -0.18deg,
      rgba(255, 255, 255, 0.45) 0.52deg,
      rgba(255, 255, 255, 0.6) 21.35deg,
      rgba(255, 251, 251, 0.8) 49.84deg,
      rgba(255, 255, 255, 0.9) 91deg,
      rgba(255, 255, 255, 0.9) 127.33deg,
      rgba(223, 223, 223, 0) 254.9deg,
      rgba(222, 222, 222, 0) 290.26deg,
      rgba(221, 221, 221, 0) 310.89deg,
      rgba(220, 220, 220, 0) 329.68deg,
      rgba(218, 218, 218, 0) 343.99deg,
      rgba(255, 254, 254, 0) 359.82deg,
      rgba(255, 255, 255, 0.45) 360.52deg);
  display: flex;
  height: 100%;
  width: 65%;
}

.trademark {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-weight: 500;
  height: 255px;
  justify-content: space-between;
  margin-left: 119px;
  width: fit-content;
  word-wrap: break-word;
}

.trademark span {
  width: 600px;
}

.trademark button {
  background-color: #ffa14a;
  border: none;
  border-radius: 100px;
  color: white;
  font-size: 32px;
  font-weight: 800;
  padding: 13px 70px 13px 30px;
  position: relative;
  cursor: pointer;
}

.trademark button:hover {
  background-color: #13a647;
  color: #ffffff;
}

.trademark svg {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: calc(50% - 15px);
}

.clients__section {
  background-color: #0A0E1B;
  color: white;
  height: 717px;
  padding: 115px 0;
}

.clients__container {
  align-items: center;
  align-content: space-between;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-around;
  margin: auto;
  width: 80%;
}

.clients__logo {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  height: min-content;
  justify-content: space-evenly;
  padding-top: 100px;
  width: 70%;
}

.carousel {
  display: none;
}

.clients__logo img {
  height: 201px;
  object-fit: contain;
  width: 246px;
}

.contact__section {
  height: 900px;
  width: 100%;
}

.contact__container {
  margin: 115px;
}

.contact__container p {
  font-size: 20px;
  font-weight: 800;
  margin: 54px 0 30px;
  text-align: center;
}

.disabled {
  visibility: hidden;
}

.enabled {
  visibility: visible;
}

.go__up {
  right: 2%;
  position: fixed;
  bottom: 3%;
  transition: all 300ms ease-in-out;
  z-index: 200;
}

.go__up button {
  background-color: #FFA14A;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
}

.go__up svg {
  font-size: 20px;
  color: white;
}

@media (max-width: 600px) {
  .drone__image {
    background-attachment: fixed;
    background-image: url(../../images/drone_compact.jpg);
    background-size: cover;
    background-position: center;
    height: 550px;
    top: 0;
    width: 100%;
  }

  .presentation__text {
    position: absolute;
    left: 34px;
    top: 360px;
    width: 300px;
  }

  .presentation__text span {
    font-size: 40px;
    font-weight: 600;
    text-wrap: wrap;
  }

  .drone__image img {
    display: none;
  }

  .cards__container {
    flex-wrap: wrap;
    gap: 45px;
    width: 100%;
  }

  .trademark__container {
    width: 100%;
  }

  .trademark {
    margin-left: 20px;
    height: 350px;
    width: 90%;
  }

  .trademark span {
    width: 268px;
  }

  .trademark button {
    background-color: #ffa14a;
    border: none;
    border-radius: 100px;
    color: white;
    font-size: 28px;
    font-weight: 800;
    padding: 11px 70px 11px 30px;
    position: relative;
    cursor: pointer;
  }

  .trademark button:active {
    background-color: #13a647;
    color: #ffffff;
  }

  .clients__section {
    height: 600px;
    padding: 85px 0;
  }

  .clients__logo {
    display: none;
  }

  .carousel {
    display: block;
    width: 95%;
  }

  .carousel img {
    height: 270px;
    object-fit: contain;
    width: 290px;
  }

  .contact__container {
    margin: 115px 0;
  }
}