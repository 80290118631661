.form__container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px auto 0;
  width: 50%;
}

.input__container {
  color: #ffa14a;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  position: relative;
}

.input__container strong {
  color: red;
}

.contact__input {
  border: none;
  border-radius: 6px;
  font-size: 18px;
  height: 50px;
  margin: 3px 0 2px 0;
  outline-color: #b15d0f;
  padding: 0 12px;
  width: 100%;
}

.contact__input::placeholder,
.contact__textBox::placeholder {
  color: #9e887a91;
}

.contact__textBox {
  border: none;
  border-radius: 6px;
  font-size: 18px;
  height: 150px;
  margin-top: 4px;
  outline-color: #b15d0f;
  padding: 12px 12px;
  resize: none;
  width: 100%;
}

.form__container button {
  background-color: #fedec0;
  border: #ffa14a solid 2px;
  border-radius: 100px;
  color: #ffa14a;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  padding: 8px 20px;
  transition: all 500ms ease-in-out;
}

.form__container button:hover {
  background-color: #ffa14a;
  color: white;
}

.form__container button:disabled {
  background-color: #ffb9b9;
  border: #ff4a4a solid 2px;
  color: #ff4a4a;
  cursor: default;
}

.invalid__mark {
  color: #ff0000;
  font-size: 32px;
  height: 32px;
  position: absolute;
  right: -15px;
  top: 45%;
}

.valid__mark {
  color: #2eb11a;
  font-size: 32px;
  height: 32px;
  position: absolute;
  right: -15px;
  top: 46%;
  width: 32px;
}

.invalid__input,
.invalid__textbox {
  background-color: #f3d6d6;
  border: #ff0000 solid 2px;
  height: 46px;
  outline-color: #ff0000;
}

.valid__textbox,
.valid__input {
  background-color: #e8fbe5;
  border: #2eb11a solid 2px;
  height: 46px;
  outline-color: #2eb11a;
}

.valid__textbox,
.invalid__textbox {
  height: 146px;
}

.err__msg {
  bottom: -11px;
  color: #ff0000;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
}

@media (max-width: 600px) {
  .form__container {
    width: 80%;
  }
}